import mapboxgl from "mapbox-gl";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import "mapbox-gl/dist/mapbox-gl.css";
import { MAP_KEY } from "../../constants/routes";
import { useEffect, useRef, useState } from "react";

mapboxgl.accessToken = MAP_KEY;

function MapBody() {
  const mapContainer = useRef(null);

  const [map, setMap] = useState<mapboxgl.Map | null>(null);

  useEffect(() => {
    if (mapContainer.current) {
      const newMap = new mapboxgl.Map({
        container: mapContainer.current, // This will be a valid HTMLElement after the component mounts
        style: "mapbox://styles/mapbox/streets-v11",
        center: [-97.9222112121185, 39.3812661305678],
        zoom: 3,
      });

      newMap.on("load", () => {
        setMap(newMap);
      });

      return () => {
        if (map) {
          map.remove();
        }
      };
    }
  }, [mapContainer.current]);

  const data = [
    {
      lat: -97.73333,
      long: 30.266666,
      status: "OPEN",
      store_name: "NY01",
      address: "399 W Louis Henna Blvd Austin TX 78728",
    },
    {
      lat: -80.191788,
      long: 25.761681,
      status: "COMING SOON",
      store_name: "NY02",
      address: "13640 W Capitol Dr Brookfield WI 53005",
    },
  ];

  useEffect(() => {
    if (map && data && data.length) {
      document
        .querySelectorAll(".mapboxgl-marker")
        .forEach((marker) => marker.remove());

      data.forEach((location) => {
        const coordinates: [number, number] = [location.lat, location.long];
        const { status, store_name, address } = location;
        let color = "green";

        if (!Array.isArray(coordinates) || coordinates.length !== 2) {
          return;
        }

        switch (status) {
          case "OPEN":
            color = "#e09400";
            break;
          case "COMING SOON":
            color = "green";
            break;
          default:
            color = "blue"; // Default color if status is unknown
        }

        // Create marker
        const marker = new mapboxgl.Marker({ color })
          .setLngLat(coordinates)
          .addTo(map);

        // Create popup content
        const popupContent = ` <span style="font-weight: bold;">Store Code: ${store_name}</span><br>
        <span>Status: <span style="color: ${color};">${status}</span></span><br>
        <span>Address: ${address}</span>
       `;

        // Create popup
        const popup = new mapboxgl.Popup().setHTML(popupContent);

        // Attach popup to marker
        marker.setPopup(popup);
      });
    }
  }, [map, data]);

  return (
    <>
      <div className="main-wrape ">
        <div className="main-inner-wrape">
          <div ref={mapContainer} className=" h-[100%] w-[1080px]"></div>
        </div>
      </div>
    </>
  );
}

export default MapBody;
