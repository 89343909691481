import Footer from "../components/commonFiles/footer";
import Header from "../components/commonFiles/header";
import MapBody from "../components/mapScreen/mapBody";

function MapScreen() {
  return (
    <>
      <Header showExit={true} />
      <MapBody />
      <Footer />
    </>
  );
}

export default MapScreen;
