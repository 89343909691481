import { Navigate, Route, Routes as ReactRoutes } from "react-router-dom";
import { ROUTES } from "./constants/routes";
import NotFound from "./pages/NotFoundPage";
import SplashScreen from "./pages/Splash";
import ScannerScreen from "./pages/Scanner";
import TransactionScreen from "./pages/TransactionDetails";
import TransactionProcess from "./pages/TransactionProcess";
import ThankyouScreen from "./pages/Thankyou";
import OopsScreen from "./pages/Oops";
import MapScreen from "./pages/Map";

const Routes = () => {
  return (
    <ReactRoutes>
      <Route path={ROUTES.HOMEPAGE.path} Component={SplashScreen} index />
      <Route path={ROUTES.SCANNER.path} Component={ScannerScreen} />
      <Route path={ROUTES.TRANSACTION.path} Component={TransactionScreen} />
      <Route path={ROUTES.TRANSACTIONPROCESS.path} Component={TransactionProcess}/>
      <Route path={ROUTES.THANKYOU.path} Component={ThankyouScreen} />
      <Route path={ROUTES.OOPS.path} Component={OopsScreen} />
      <Route path={ROUTES.MAP.path} Component={MapScreen} />
      <Route 
        path={ROUTES.NOT_FOUND.path} 
        Component={NotFound} 
      />
      <Route
        path="*"
        Component={() => <Navigate to={ROUTES.HOMEPAGE.path} />}
      />
    </ReactRoutes>
  );
};

export default Routes;
