import Footer from "../components/commonFiles/footer";
import Header from "../components/commonFiles/header";
import OopsBody from "../components/oopsScreen/oopsBody";
import { useCallback } from "react";
import { ROUTES } from "../constants/routes";

function OopsScreen() {
  const onTimerFinish = useCallback(() => {
    window.location.href = ROUTES.HOMEPAGE.path;
  }, []);
  return (
    <>
      <Header duration={5} startTimer onTimerFinish={onTimerFinish} />
      <OopsBody />
      <Footer />
    </>
  );
}

export default OopsScreen;
