/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from "axios"

export enum EComponent  {
    "IMAGING_DEVICE" =  "BarcodeScanner",
    "BILL_DISPENSER" = "BillDispenser",
}

const client = axios.create({
    baseURL: 'http://localhost:8888/api',
    timeout: 5*60*1000
  });

export const getStatus = async (component:EComponent)=>{
    const {data} =  await client.get(`/Status/${component}`)
    return data
}

export const executeCancelCommand = async (components:EComponent[])=>{
    const data = await Promise.allSettled(components.map(component=>client.post(`/Cancel/${component}`,{
        TimeoutMs: 60000
    })))

    return data
}

export const scan = async (TimeoutMs?:number)=>{
    const {data} =  await client.post(`/Scan/${EComponent.IMAGING_DEVICE}`,{
        BeepOnScan: true,
        TimeoutMs: TimeoutMs||60000
    })
    return data
}

export const dispense = async ({amount,TimeoutMs}:{amount:number,TimeoutMs?:number})=>{
    const {data} =  await client.post(`/Dispense/${EComponent.BILL_DISPENSER}`,{
        Total:amount,
        TimeoutMs: TimeoutMs||60000,
        TotalLimit: 1000000
    })
    return data
}

export const parseResponse = (response:any)=>{
    const IsSuccess = response?.IsSuccess;
    const state = response?.Status?.State;
    const activity = response?.Status?.Activity;

    return {
        IsSuccess,
        state,
        activity,
        ...response
    }
}