function ScannerBody() {
  return (
    <div className="main-wrape ">
      <div className="main-inner-wrape h-[1718px]">
        <div className="top-txt">Scan QR Code</div>

        <a href="/transaction">
          <img
            src="/assets/icons/QRCode.png"
            className="QR-img"
            alt="QRCode"
          ></img>
        </a>
        <img
          src="https://paymore.s3.us-west-1.amazonaws.com/PayMore-KIOSK/arrow.gif"
          className="arrow-gif-img"
          alt="arrow"
        ></img>
        <img
          src="/assets/icons/phone.png"
          className="phone-img"
          alt="phone"
        ></img>
        <img
          src="/assets/icons/phoneGirl.png"
          className="phone-girl-img"
          alt="phoneGirl"
        ></img>
        <div className="bottom-text-scanner">
          Scan your QR code to get paid!
        </div>
        <div className="triangle-shap-div"></div>
      </div>
    </div>
  );
}

export default ScannerBody;
