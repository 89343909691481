import Footer from "../components/commonFiles/footer";
import Header from "../components/commonFiles/header";
import ThankyouBody from "../components/thankyouScreen/thankyouBody";
import { useCallback } from "react";
import { ROUTES } from "../constants/routes";

function ThankyouScreen() {
  const onTimerFinish = useCallback(() => {
    window.location.href = ROUTES.HOMEPAGE.path
  },[])
  return (
    <>
      <Header duration={150} startTimer onTimerFinish={onTimerFinish}/>
      <ThankyouBody />
      <Footer />
    </>
  );
}

export default ThankyouScreen;
