import { useLocation, useNavigate } from "react-router-dom";
import Footer from "../components/commonFiles/footer";
import Header from "../components/commonFiles/header";
import TransactionBody from "../components/transactionScreen/transactionBody";
import { useCallback, useEffect, useMemo } from "react";
import { ROUTES } from "../constants/routes";

function TransactionScreen() {
  const location = useLocation()
  const navigate = useNavigate()
  const state = useMemo(() => location.state as {name:string, amount: number}, [location])
  console.log("state on trasaction page",state);

  const onTimerFinish = useCallback(() => {
    window.location.href = ROUTES.HOMEPAGE.path
  },[])

  const onProcessTransaction = useCallback(async () => {
    try {
      navigate(ROUTES.TRANSACTIONPROCESS.path,{
        state
      })
    } catch (error) {
      console.log("error",error);
      alert(error)
      window.location.href = ROUTES.HOMEPAGE.path
    }
  },[state,navigate])
  

  useEffect(() => {
    if(!state){
      window.location.href = ROUTES.HOMEPAGE.path
    }
  }, [state])
  

  return (
    <>
      <Header duration={70} startTimer onTimerFinish={onTimerFinish}/>
      <TransactionBody name={state.name} amount={state.amount} onProcessTransactionPromise={onProcessTransaction}/>
      <Footer />
    </>
  );
}

export default TransactionScreen;
