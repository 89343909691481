export const ROUTES = {
    HOMEPAGE: {
        path: "/"
    },
    CASH_WITHDRAW: {
        path: "/cash-withdraw"
    },
    NOT_FOUND: {
        path: "/not-found"
    },
    SPLASH:{
        path:"/splash"
    },
    SCANNER:{
        path:"/scanner"
    },
    TRANSACTION:{
        path:"/transaction"
    },
    TRANSACTIONPROCESS:{
        path:"/process"
    },
    THANKYOU:{
        path:"/thankyou"
    },
    OOPS:{
        path:"/oops"
    },
    MAP:{
        path:"/map"
    }
}

export const MAP_KEY =
  "pk.eyJ1IjoicGF5bW9yZSIsImEiOiJjbG1mYjFuZXkwOTdoM2V0anFnMTR0ZXM3In0.qfFj_7G4mBrvALJfubmuHg";
