import { useCallback, useState } from "react";
import { Link } from "react-router-dom";
import { ROUTES } from "../../constants/routes";

interface Props{
  name: string;
  amount: number;
  onProcessTransactionPromise: ()=>Promise<unknown>
}
function TransactionBody({name, amount, onProcessTransactionPromise}:Props) {
  const [isOpen, setIsOpen] = useState(false);

  const openDialog = useCallback(() => {
    setIsOpen(true);
    document.body.classList.add("modal-open");
  },[]);

  const closeDialog = useCallback(() => {
    setIsOpen(false);
    document.body.classList.remove("modal-open");
  },[]);

  return (
    <>
      <div className="main-wrape ">
        <div className="main-inner-wrape">
          <img
            src="/assets/icons/moneyBoy.png"
            className="absolute h-[600px] w-[605px] bottom-[0px] left-[185px] right-0 top-0"
            alt="moneyBoy"
          ></img>
          <div className="absolute  left-[320px] right-0 bottom-[0px] top-[640px] ">
            <div className="gap-4 flex">
              <div className="font-inter-font text-[60px] font-[500] text-black leading-8">
                Hey
              </div>
              <div className="font-inter-font text-[60px] font-[500] text-[#64BB46] leading-8">
                {name}!
              </div>
            </div>

            <div className="absolute top-20  font-inter-font text-[46px] font-[500] left-0 text-black leading-8">
              Time to get paid!
            </div>
          </div>
          <div className="absolute top-[730px] font-inter-font text-[140px] font-[900] left-[180px] right-0 text-[#64BB46]">
            ${amount.toFixed(2)}
          </div>

          <div
            onClick={() => {
              openDialog();
            }}
            className="cursor-pointer flex gap-2 items-center absolute top-[920px] font-inter-font text-[30px] font-[500] left-[350px] right-0 text-[#7E8299]"
          >
            <div>Transaction Details</div>
            <img
              src="/assets/icons/downArrow.png"
              className="h-[30px] w-[30px] flex items-center"
              alt="downArrow"
            ></img>
          </div>

          <div className="">
            <div className="flex font-inter-font text-[35px] font-[400] text-white left-10 absolute  bottom-[55px]">
              Is this correct?
            </div>

            <div className=" gap-4 absolute left-[600px] flex bottom-[38px]">
              <button className="border-[3px] border-white  rounded-[66px] bg-[#357E00] h-[89px] w-[174px] text-[#64BB46] font-inter-font text-[32px] font-[600] " onClick={onProcessTransactionPromise}>
                Yes
              </button>
              <Link to={ROUTES.OOPS.path}>
                <button className="border-[3px] border-white  rounded-[66px] bg-[#C5C5C5] h-[89px] w-[174px] text-white font-inter-font text-[32px] font-[600] ">
                  No
                </button>
              </Link>
            </div>
          </div>

          <div className="triangle-shap-div-transaction"></div>
        </div>
      </div>

      {isOpen && (
        <>
          <div className="modal-overlay">
            <div className="main-modal-wrapper">
              <div className="main-modal">
                <div className="modal-container">
                  <div>
                    <div className="px-4 pt-4 pb-1 flex justify-between">
                      <p className="text-black font-inter-font text-[27px] font-[600]">
                        Order Summary
                      </p>
                      <div
                        onClick={closeDialog}
                        className="cursor-pointer text-[#FF0404] font-inter-font text-[28px] font-[600]"
                      >
                        EXIT
                      </div>
                    </div>

                    <hr />
                    <div className="px-6 pt-4 pb-2 flex justify-between">
                      <p className="text-black font-inter-font text-[22px] font-[500]">
                        Order No
                      </p>
                      <div className="text-black font-inter-font text-[22px] font-[500]">
                        Date
                      </div>
                    </div>
                    <div className="px-6 pt-1 pb-4 flex justify-between">
                      <p className="text-black font-inter-font text-[22px] font-[300]">
                        123NCA
                      </p>
                      <div className="text-black font-inter-font text-[22px] font-[300]">
                        03/25/2024
                      </div>
                    </div>
                    <hr />
                    <div className="flex flex-col ">
                      <div className=" ">
                        <div className="py-2 inline-block min-w-full ">
                          <div className="overflow-hidden">
                            <table className="min-w-full">
                              <thead className="bg-white ">
                                <tr className="">
                                  <th
                                    scope="col"
                                    className="text-black font-inter-font text-[22px] font-[600] pl-4  py-4 text-left "
                                  >
                                    Sr. No.
                                  </th>
                                  <th
                                    scope="col"
                                    className="text-black font-inter-font text-[22px] font-[600]  py-4 text-left"
                                  >
                                    Name
                                  </th>
                                  <th
                                    scope="col"
                                    className="text-black font-inter-font text-[22px] font-[600] py-4 text-right"
                                  >
                                    Qty
                                  </th>
                                  <th
                                    scope="col"
                                    className="text-black font-inter-font text-[22px] font-[600] pr-4 py-4 text-right"
                                  >
                                    Amount
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr className="">
                                  <td className="text-[#3F4254] font-inter-font text-[22px] font-[300] pl-4 pb-2 text-left">
                                    1
                                  </td>
                                  <td className="text-[#3F4254] font-inter-font text-[22px] font-[300] pb-2 text-left">
                                    iphone 14 plus | 256gb
                                  </td>
                                  <td className="text-[#3F4254] font-inter-font text-[22px] font-[300] pb-2 text-right">
                                    1
                                  </td>
                                  <td className="text-[#3F4254] font-inter-font text-[22px] font-[300] pb-2 pr-4 text-right">
                                    $300.00
                                  </td>
                                </tr>

                                <tr className="border-t ">
                                  <td className="text-[#3F4254] font-inter-font text-[22px] font-[300] pl-4  text-left"></td>
                                  <td className="text-[#3F4254] font-inter-font text-[22px] font-[300] text-left"></td>
                                  <td className="text-black font-inter-font text-[22px] font-[600] py-3 text-right ">
                                    Total
                                  </td>
                                  <td className="text-black font-inter-font text-[22px] font-[600] pr-4 text-right ">
                                    $300.00
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default TransactionBody;
