import { Link } from "react-router-dom";
import { ROUTES } from "../constants/routes";

const NotFound = () => {
  return (
    <div className="w-full h-[100vh] flex items-center justify-center">
      <div className="flex flex-col gap-2 items-center">
        <h1 className="text-gray-500 text-9xl">404</h1>
        <Link to={ROUTES.HOMEPAGE.path}>Return back to home</Link>
      </div>
    </div>
  );
};

export default NotFound;
