import { useState, useEffect, useMemo } from "react";
import Carousel from "react-spring-3d-carousel";
import { config } from "react-spring";
import { Link } from "react-router-dom";
import { ROUTES } from "../../constants/routes";

function SplashBody() {
  const [autoplayIndex, setAutoplayIndex] = useState(0);

  const images = useMemo(
    () =>
      sliderImages.map((imageData) => ({
        key: imageData.key,
        content: (
          <CommonImage
            src={imageData.src}
            alt={imageData.key.toString()}
            index={imageData.index}
          />
        ),
      })),
    []
  );

  useEffect(() => {
    const intervalId = setInterval(() => {
      setAutoplayIndex((prevIndex) => (prevIndex + 1) % sliderImages.length);
    }, AUTOPLAY_DELAY);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <>
      <div className="main-wrape ">
        <div className="main-inner-wrape">
          {/* vertical bottom rounded */}
          <div className="screen-size">
            {/* gif */}
            <img
              src="/assets/gif/moneyRain.gif"
              className="gif-size"
              alt="gif"
            />
            {/* slider */}
            <div className="slider-main-div">
              <div className="slider-inner-div">
                <Carousel
                  showNavigation={false}
                  slides={images}
                  goToSlide={autoplayIndex}
                  offsetRadius={2}
                  animationConfig={config.gentle}
                />
              </div>
            </div>
            {/* girl 1 */}
            <img
              src="/assets/icons/umbrellaGirl.png"
              alt="umbrella-girl"
              className="girl1-img-size"
            />
            {/* left text */}
            <span className="left-txt-wrape">
              <div>
                <div className="left-text1">1,456,901 +</div>
                <div className="left-text2">Devices Traded In</div>
              </div>
            </span>
            {/* left dot */}
            <span className="left-dot-wrape">
              <span className="left-dot"></span>
            </span>
            {/* left line */}
            <img
              src="/assets/icons/leftLine.png"
              alt="left-line"
              className="left-line-size"
            />
            {/* left vector */}
            <img
              src="/assets/icons/vector1.png"
              alt="vector1"
              className="left-vector-size"
            />
            {/* center text */}
            <span className="center-txt-wrape">
              <div>
                <Link to={ROUTES.MAP.path}>
                  <div className="center-text1">24</div>
                  <div className="center-text2">Paymore Store Open</div>
                </Link>
              </div>
            </span>

            {/* center animated dot */}

            <span className="center-animated-dot-wrape">
              <span className="center-shadow-dot1"></span>
              <span className="center-shadow-dot2"></span>
              <span className="center-shadow-dot3"></span>
            </span>
            {/* center line */}
            <img
              src="/assets/icons/centerLine.png"
              alt="center-line"
              className="center-line-size"
            />
            {/* center vector */}
            <img
              src="/assets/icons/vector2.png"
              alt="vector2"
              className="center-vector"
            />
            {/* girl 2 */}
            <img
              src="/assets/icons/umbrellaMoneyGirl.png"
              alt="umbrella-money-girl"
              className="girl2-img-size"
            />
            {/* right text */}
            <span className="right-txt-wrapper">
              <span>
                <span className="right-txt1">$100 +</span>
                <span className="right-txt2">Paymore Cash Given</span>
              </span>
            </span>
            {/* right dot */}

            <span className="right-dot-wrapper">
              <span className="right-dot"></span>
            </span>

            {/* right line */}
            <img
              src="/assets/icons/rightLine.png"
              alt="right-line"
              className="right-line-size"
            />
            {/* right vector */}
            <img
              src="/assets/icons/vector3.png"
              alt="vector3"
              className="right-vector"
            />
            {/* center hand icn */}
            <Link to={ROUTES.SCANNER.path}>
              <img
                src="/assets/icons/handIcon.png"
                alt="hand-icon"
                className="hand-icn-size"
              />
            </Link>
            {/* center animated circle  */}
            <span className="animated-circle-wrape">
              <span className="animated-circle1"></span>
              <span className="animated-circle2"></span>
              <span className="animated-circle3"></span>
              <span className="animated-circle4"></span>
            </span>
            {/* bottom text */}
            <div className="bottom-text">Scan your QR code to get paid!</div>
          </div>
        </div>
      </div>
    </>
  );
}

const AUTOPLAY_DELAY = 3000;

const sliderImages = [
  {
    key: 1,
    src: "/assets/sliderImages/slider1.jpg",
    index: 0,
  },
  {
    key: 2,
    src: "/assets/sliderImages/slider2.jpg",
    index: 1,
  },
  {
    key: 3,
    src: "/assets/sliderImages/slider3.jpg",
    index: 2,
  },
  {
    key: 4,
    src: "/assets/sliderImages/slider4.jpg",
    index: 3,
  },
  {
    key: 5,
    src: "/assets/sliderImages/slider5.jpg",
    index: 4,
  },
];

interface CommonImageProps {
  src: string;
  alt: string;
  index: number;
}

const CommonImage: React.FC<CommonImageProps> = ({ src, alt, index }) => {
  return (
    <div>
      <img
        src={src}
        alt={alt}
        className="slider-image-size"
        key={index}
        style={{ objectFit: "fill" }}
      />
    </div>
  );
};

export default SplashBody;
