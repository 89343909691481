import * as Sentry from "@sentry/react";
import { useLocation, useNavigate } from "react-router-dom";
import Footer from "../components/commonFiles/footer";
import Header from "../components/commonFiles/header";
import ProcessBody from "../components/transactionProcess/processBody";
import { useCallback, useEffect, useMemo, useState } from "react";
import { ROUTES } from "../constants/routes";
import { EComponent, dispense, executeCancelCommand, getStatus, parseResponse } from "../services/kiosk";

function TransactionProcess() {
  const [isDispenseStarted, setIsDispenseStarted] = useState(false);

  const location = useLocation()
  const navigate = useNavigate()


  const state = useMemo(() => location.state as {name:string, amount: number}, [location])
  console.log("state",state);

  const onTimerFinish = useCallback(() => {
    window.location.href = ROUTES.OOPS.path
  },[])


  const dispenseCash = useCallback(async () => {
    console.log("dispenseCash function started");
    
    try {
      console.log("Executing cancel command for BILL_DISPENSER");
      const res = await executeCancelCommand([EComponent.BILL_DISPENSER]);
      const cancelCommandResponse = res[0];
      
      console.log("Cancel command response received:", cancelCommandResponse);
      Sentry.captureMessage(JSON.stringify({ cancelCommandResponse }));
  
      if (cancelCommandResponse.status === "fulfilled") {
        console.log("Cancel command fulfilled, parsing response");
        const parsedCancelCommandResponse = parseResponse(cancelCommandResponse.value.data);
        
        console.log("Parsed cancel command response:", parsedCancelCommandResponse);
        Sentry.captureMessage(JSON.stringify({ parsedCancelCommandResponse }));
  
        if (parsedCancelCommandResponse.IsSuccess) {
          console.log("Cancel command was successful, fetching dispenser status");
          const dispenserStatusResponse = await getStatus(EComponent.BILL_DISPENSER);
          
          console.log("Dispenser status response received:", dispenserStatusResponse);
          Sentry.captureMessage(JSON.stringify({ dispenserStatusResponse }));
  
          const parsedDispenserStatusResponse = parseResponse(dispenserStatusResponse);
          
          console.log("Parsed dispenser status response:", parsedDispenserStatusResponse);
          Sentry.captureMessage(JSON.stringify({ parsedDispenserStatusResponse }));
  
          if (parsedDispenserStatusResponse.IsSuccess) {
            console.log("Dispenser status is successful, starting cash dispensing");
            Sentry.captureMessage("Dispensing cash now");
            setIsDispenseStarted(true);
  
            const dispenserDispenseResponse = await dispense({
              amount: state.amount,
              TimeoutMs: timeoutSeconds * 1000
            });
            
            console.log("Dispenser dispense response received:", dispenserDispenseResponse);
            Sentry.captureMessage(JSON.stringify({ dispenserDispenseResponse }));
  
            const parsedDispenserDispenseResponse = parseResponse(dispenserDispenseResponse);
            
            console.log("Parsed dispenser dispense response:", parsedDispenserDispenseResponse);
            Sentry.captureMessage(JSON.stringify({ parsedDispenserDispenseResponse }));
  
            if (parsedDispenserDispenseResponse.IsSuccess) {
              console.log("Cash dispensed successfully, navigating to THANK YOU page");
              navigate(ROUTES.THANKYOU.path);
            } else {
              console.error("Dispenser dispense response indicates failure");
              Sentry.captureMessage("parsedDispenserDispenseResponse is not success", "error");
              throw new Error("parsedDispenserDispenseResponse is not success");
            }
          } else {
            console.error("Dispenser status response indicates failure");
            Sentry.captureMessage("parsedDispenserStatusResponse is not success", "error");
            throw new Error("parsedDispenserStatusResponse is not success");
          }
        } else {
          console.error("Cancel command response indicates failure");
          Sentry.captureMessage("parsedCancelCommandResponse is not success", "error");
          throw new Error("parsedCancelCommandResponse is not success");
        }
      } else {
        console.error("Cancel command response was not fulfilled");
        Sentry.captureMessage("cancelCommandResponse is not fulfilled", "error");
        throw new Error("cancelCommandResponse is not fulfilled");
      }
    } catch (error) {
      console.error("Error in dispenseCash function:", error);
      
      // TODO uncomment this to test transaction screen with data start
      navigate(ROUTES.THANKYOU.path)
      return
      // TODO for testing end
  
      Sentry.captureException(error);
      alert(error);
      window.location.href = ROUTES.OOPS.path;
    }
  }, [state, navigate]);
  

  useEffect(() => {
    if(state){
      dispenseCash();
    }else{
      window.location.href = ROUTES.OOPS.path
    }
  }, [state,dispenseCash])
  
  return (
    <>
      <Header duration={timeoutSeconds} startTimer={isDispenseStarted} onTimerFinish={onTimerFinish}/>
      <ProcessBody />
      <Footer />
    </>
  );
}

const timeoutSeconds = 50;

export default TransactionProcess;
