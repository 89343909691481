function ProcessBody() {
  return (
    <div className="main-wrape ">
      <div className="main-inner-wrape">
        <div className="absolute top-[202px] left-[191px] flex items-center font-inter-font text-[36px] font-[500] text-black ">
          Your transaction is being Processed.
        </div>

        <div className="absolute top-[262px] left-[310px] flex items-center font-inter-font text-[60px] font-[700] text-[#64BB46] ">
          Please wait..!
        </div>
        <a href="/thankyou">
          <img
            src="/assets/gif/cash.gif"
            className="absolute h-[719px] w-[719px] top-[365px] left-[146px]"
            alt="cash"
          ></img>
        </a>

        <div className="triangle-shap-div-process"></div>
      </div>
    </div>
  );
}

export default ProcessBody;
