import { useCallback, useEffect, useState } from "react";
import * as Sentry from "@sentry/react";
import Footer from "../components/commonFiles/footer";
import Header from "../components/commonFiles/header";
import ScannerBody from "../components/scannerScreen/scannerBody";
import { EComponent, executeCancelCommand, getStatus, parseResponse, scan } from "../services/kiosk";
import { ROUTES } from "../constants/routes";
import { useNavigate } from "react-router-dom";

function ScannerScreen() {
  const [isScanningBarcode, setIsScanningBarcode] = useState(false);
  const navigate = useNavigate()


  const onTimerFinish = useCallback(() => {
    window.location.href = ROUTES.OOPS.path
  },[])
  let money = prompt("enter amount---")

  const startScanner = useCallback(async () => {
    try {
      console.log("Starting scanner...");
  
      const res = await executeCancelCommand([EComponent.IMAGING_DEVICE]);
      console.log("Cancel command executed, response:", res);
  
      const cancelCommandResponse = res[0];
      Sentry.captureMessage(JSON.stringify({ cancelCommandResponse }));
      console.log("cancelCommandResponse:", cancelCommandResponse);
  
      if (cancelCommandResponse.status === "fulfilled") {
        console.log("Cancel command fulfilled, parsing response...");
  
        const parsedCancelCommandResponse = parseResponse(cancelCommandResponse.value.data);
        Sentry.captureMessage(JSON.stringify({ parsedCancelCommandResponse }));
        console.log("parsedCancelCommandResponse:", JSON.stringify(parsedCancelCommandResponse, null, 2));
  
        if (parsedCancelCommandResponse.IsSuccess) {
          console.log("Cancel command successful, getting scanner status...");
  
          const scannerStatusResponse = await getStatus(EComponent.IMAGING_DEVICE);
          Sentry.captureMessage(JSON.stringify({ scannerStatusResponse }));
          console.log("scannerStatusResponse:", scannerStatusResponse);
  
          const parsedScannerStatusResponse = parseResponse(scannerStatusResponse);
          Sentry.captureMessage(JSON.stringify({ parsedScannerStatusResponse }));
          console.log("parsedScannerStatusResponse:", JSON.stringify(parsedScannerStatusResponse, null, 2));
  
          if (parsedScannerStatusResponse.IsSuccess) {
            console.log("Scanner status successful, starting scan...");
  
            setIsScanningBarcode(true);
            const scannerScanResponse = await scan(timeoutSeconds * 1000);
            console.log("scannerScanResponse:", JSON.stringify(scannerScanResponse));
            Sentry.captureMessage(JSON.stringify({ scannerScanResponse }));
  
            const parsedScannerScanResponse = parseResponse(scannerScanResponse);
            console.log("parsedScannerScanResponse:", JSON.stringify(parsedScannerScanResponse, null, 2));
            Sentry.captureMessage(JSON.stringify({ parsedScannerScanResponse }));
  
            if ('ResponseData' in parsedScannerScanResponse) {
              console.log("ResponseData key exists in parsedScannerScanResponse:", parsedScannerScanResponse.ResponseData);
            } else {
              console.log("ResponseData key does NOT exist in parsedScannerScanResponse");
            }
            
            if (parsedScannerScanResponse.IsSuccess) {
              const data = parsedScannerScanResponse?.ResponseData?.Barcode;
              console.log("Barcode data:", data);
  
              if (data) {
                Sentry.captureMessage(data);
                console.log("Navigating to transaction with data:", { name: "Thomas", amount: Number(money) });
                navigate(ROUTES.TRANSACTION.path, {
                  state: {
                    name: "Thomas",
                    amount: Number(money),
                  },
                });
              } else {
                Sentry.captureMessage("No Data found", "error");
                console.error("No barcode data found");
                throw "No data found";
              }
            } else {
              Sentry.captureMessage("parsedScannerScanResponse is not success", "error");
              console.error("Scan response was not successful");
              throw "parsedScannerScanResponse is not success";
            }
          } else {
            Sentry.captureMessage("parsedScannerStatusResponse is not success", "error");
            console.error("Scanner status response was not successful");
            throw "parsedScannerStatusResponse is not success";
          }
        } else {
          Sentry.captureMessage("parsedCancelCommandResponse is not success", "error");
          console.error("Cancel command response was not successful");
          throw "parsedCancelCommandResponse is not success";
        }
      } else {
        Sentry.captureMessage("cancelCommandResponse is not fulfilled", "error");
        console.error("Cancel command response was not fulfilled");
        throw "cancelCommandResponse is not fulfilled";
      }
    } catch (error) {
      console.error("An error occurred:", error);
  
      // TODO uncomment this to test transaction screen with data start
      navigate(ROUTES.TRANSACTION.path,{
        state:{
          name: "Thomas",
          amount:Number(money)
        }
      })
      return
      // TODO for testing end
  
      Sentry.captureException(error);
      alert(error);
      window.location.href = ROUTES.OOPS.path;
    }
  }, [navigate]);
  

  useEffect(() => {
    startScanner()
  }, [startScanner])
  

  return (
    <>
      <Header duration={timeoutSeconds} startTimer={isScanningBarcode} onTimerFinish={onTimerFinish}/>
      <ScannerBody />
      <Footer />
    </>
  );
}

const timeoutSeconds = 130;

export default ScannerScreen;
