import { useEffect } from "react";
import * as Sentry from "@sentry/react";
import Footer from "../components/commonFiles/footer";
import Header from "../components/commonFiles/header";
import SplashBody from "../components/splashScreen/splashBody";
import { EComponent ,executeCancelCommand } from "../services/kiosk";

// cancel all the components operations
const executeCancelCommandForAllDevices = async () => {
  try {
    const cancelResponse = await executeCancelCommand([EComponent.BILL_DISPENSER,EComponent.IMAGING_DEVICE])
    console.log("cancelResponse",cancelResponse);
  } catch (error) {
    Sentry.captureException(error)
    console.log("error in executeCancelCommandForAllDevices",error)
  }
}

function SplashScreen() {
  
  useEffect(() => {
    executeCancelCommandForAllDevices();
  }, [])

  return (
    <>
      <div className="relative w-[1080px] h-[1920px] ">
        <Header />
        <SplashBody />
        <Footer />
      </div>
    </>
  );
}

export default SplashScreen;
