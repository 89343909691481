import { useEffect, useMemo, useState } from "react";

interface Props {
  duration?: number;
  startTimer?: boolean;
  showExit?: boolean;
  onTimerFinish?: () => void;
}
export default function Header({
  duration,
  startTimer,
  onTimerFinish,
  showExit,
}: Props) {
  const [timeLeft, setTimeLeft] = useState(duration);
  const [isRunning, setIsRunning] = useState(false);

  // Effect to start the timer when startTimer changes
  useEffect(() => {
    if (startTimer) {
      setIsRunning(true);
      setTimeLeft(duration);
    } else {
      setIsRunning(false);
    }
  }, [startTimer, duration]);

  // Effect to handle the countdown logic
  useEffect(() => {
    if (timeLeft !== undefined) {
      if (isRunning && timeLeft > 0) {
        const timerId = setTimeout(() => {
          setTimeLeft(timeLeft - 1);
        }, 1000);
        return () => clearTimeout(timerId);
      } else if (isRunning && timeLeft === 0) {
        setIsRunning(false);
        onTimerFinish && onTimerFinish();
      }
    }
  }, [isRunning, timeLeft, onTimerFinish]);

  // Function to format time as mm:ss
  const formattedTime = useMemo(() => {
    if (timeLeft === undefined) {
      return ``;
    }
    const minutes = Math.floor(timeLeft / 60);
    const remainingSeconds = timeLeft % 60;
    return `${String(minutes).padStart(2, "0")}:${String(
      remainingSeconds
    ).padStart(2, "0")}`;
  }, [timeLeft]);

  return (
    <div className="header-wrapper">
      <div className="header-inner-container">
        {/* <a href="/"> */}
        <img
          className="logo-size"
          src="/assets/icons/headerLogo.png"
          alt="header-logo"
        />
        {/* </a> */}
        <div className="">
          {isRunning ? (
            <>
              <div className="animate-scaleUp">
                <img
                  src="/assets/icons/timer.png"
                  alt="timer"
                  className="timer-img-size"
                />
                <p className="timer-txt">{formattedTime}</p>
              </div>
            </>
          ) : (
            ""
          )}

          {showExit ? (
            <>
              <a href="/splash">
                <p className="cursor-pointer font-inter-font font-[500] text-[28px] text-[#FF0404] ">
                  Exit
                </p>
              </a>
            </>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
}
